import ReactDOM from 'react-dom'

import './styles/shared/reset.css'
import './Index.module.css'

import App from './App'
import * as serviceWorker from './serviceWorker'

// here app catches the suspense from page in case translations are not yet loaded
function LoadingApp() {
  return <App />
}

ReactDOM.render(<LoadingApp />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
