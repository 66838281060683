import React from 'react'

import LogoHorizontal from './components/Logos/LogoHorizontal'
import StrokeLogo from './components/Logos/StrokeLogo.svg'

import css from './App.module.css'

const Page: React.FC<{}> = () => {
  return (
    <div className={css.host}>
      <div
        className={css.backgroundImageWrapper}
        style={{ backgroundImage: `url(${StrokeLogo})` }}
      />
      <div className={css.logoWrapper}>
        <LogoHorizontal mode="dark" />
        <h1 className={css.text}>Hyper-personalized global luxury shopping.</h1>
        <a
          className={css.link}
          href="https://styleyield.typeform.com/waitlist"
          target="_blank"
          rel="noreferrer"
        >
          Join our waitlist
        </a>
      </div>
      <h3 className={css.tagline}>Coming soon</h3>
    </div>
  )
}

export default function App() {
  return <Page />
}
